
  import { Component, Prop, Vue, Emit } from "vue-property-decorator";

  @Component
  export default class PagePropertyPartGeneralSubHeader extends Vue {
    @Prop({ required: true }) tabs!: any[];
    @Prop({ required: true }) active!: string;

    /**
     * Set the active tab and emit it to the parent
     *
     * @param {string} id
     */
    @Emit("tab-changed")
    setActive(id: string) {
      return id;
    }
  }
